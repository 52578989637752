import React, { useState } from 'react';

import { graphql } from 'gatsby';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout.jsx';
import CareersBlock from '../components/careers-block.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import Testimonials from '../components/testimonials.jsx';

export default function Company(props) {
	let { data } = props;
	let { sanityTeamPage } = data;
	let { title, image, employees, seo } = sanityTeamPage;
	let [activeEmployee, setActiveEmployee] = useState();

	function handleEmployeeClick(employee) {
		if (activeEmployee?._key === employee._key) {
			setActiveEmployee();
		} else {
			setActiveEmployee(employee);
		}
	}

	let renderEmployees = [];
	for (let employee of employees) {
		let name = `${employee.firstname} ${employee.lastname}`;

		let arrowClassName = classNames('w-10 h-10 transition-transform transform', {
			'rotate-90': employee._key === activeEmployee?._key,
		});

		let extraInfoClassName = classNames('mt-6', {
			hidden: employee._key !== activeEmployee?._key,
			block: employee._key === activeEmployee?._key,
		});

		let renderImage;
		if (employee._key === activeEmployee?._key) {
			renderImage = (
				<img
					src={`${employee.crazyImage?.asset?.url}?w=320&h=320&fit=crop`}
					alt={name}
					className="object-cover w-full rounded-2xl h-80"
				/>
			);
		} else {
			let imageContainerClassName = classNames('grid w-full grid-cols-1 grid-rows-1 overflow-hidden rounded-2xl h-80', {
				'filter brightness-50 group-hover:brightness-100':
					activeEmployee != undefined && activeEmployee._key !== employee._key,
			});

			renderImage = (
				<div className={imageContainerClassName}>
					<img
						src={`${employee.normalImage?.asset?.url}?w=320&h=320&fit=crop`}
						alt={name}
						className="object-cover w-full h-full col-start-1 row-start-1 transition-opacity duration-700 transform opacity-100 group-hover:opacity-20"
					/>
					<img
						src={`${employee.crazyImage?.asset?.url}?w=320&h=320&fit=crop`}
						alt={name}
						className="object-cover w-full h-full col-start-1 row-start-1 transition-opacity duration-700 transform opacity-0 group-hover:opacity-100"
					/>
				</div>
			);
		}

		renderEmployees.push(
			<article key={employee._key} className="max-w-xs group">
				<button className="text-left focus:outline-none " onClick={() => handleEmployeeClick(employee)}>
					{renderImage}
					<h2 className="flex items-center justify-between mt-4 text-primary">
						<span className="flex flex-col text-2xl">
							<span>{employee.firstname}</span>
							<span>{employee.lastname}</span>
						</span>
						<svg className={arrowClassName} fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
						</svg>
					</h2>
					<h3 className="text-sm text-tertiary">{employee.function}</h3>
				</button>
				<div className={extraInfoClassName}>
					<p className="mt-2">{employee.description}</p>
				</div>
			</article>,
		);
	}

	return (
		<Layout seo={seo}>
			<section className="grid min-h-screen">
				<h1 className="z-10 text-4xl md:text-5xl 2xl:text-6xl xl:max-w-3xl self-y-center">
					<TitleBlock title={title} />
				</h1>
				<div
					className="right-0 self-x-end filter brightness-75 absolute w-full h-full max-w-[95%] md:max-w-[80%] min-h-[50rem] col-start-1 row-start-1 bg-no-repeat bg-cover"
					style={{
						backgroundImage: `url(${image?.asset?.url+"?h=800"})`,
						WebkitMaskImage: 'radial-gradient(ellipse at 60% 40%, white 30%, rgba(0, 0, 0, 0.0) 60%)',
					}}
				></div>
			</section>

			<Section>
				<div className="grid gap-16 items-y-start items-x-center md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
					{renderEmployees}
				</div>
			</Section>

			<CareersBlock />

			<Section>
				<div className="flex justify-center max-w-3xl mx-auto">
					<StaticImage src="../images/ship-dock.svg" alt="Ship with dock" />
				</div>
			</Section>
		</Layout>
	);
}

export const query = graphql`
	query TeamPage($language: String!) {
		sanityTeamPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			image {
				asset {
					url
				}
			}
			employees {
				_key
				firstname
				lastname
				function
				description
				normalImage {
					asset {
						url
					}
				}
				crazyImage {
					asset {
						url
					}
				}
			}
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}
	}
`;
